<template>
  <div class="w-full p-3 flex flex-col gap-2 text-darkPurple">
    <h1 class="w-full h-12 flex flex-col justify-start items-start text-xl font-bold">
      Documents
      <span class="inline-block w-full h-0 p-0" style="border: 0.5px solid #d6dbe4" />
    </h1>

    <FileUploadFolderCreation 
      :createOptionBox="folderOptions" 
      :createFolders="folderCreation"
      :uploadOptionBox="uploadOption"
      :uploadFile="fileUpload"
      @creations="settings.createFolders = $event"
      @upload="settings.uploadFile = $event"
    />

    <Permissions
      name="Folders" 
      type="created folders" 
      title="Default Folder Permissions"
      :allowOverride="settings.isAdminOverrideFolders"
      :restrictSwitch="settings.restrictFolderSwitch"
      visibilityLabel="All folders are always visible to admins."
      :isVisible="settings.isVisibilityFolder"
      :restrictActions="folderRestrictions"
      @input="getOverrideValue"
      @change="getVisibleValue"
      @restriction="getRestrictionsValue"
    />

    <Permissions
      name="Files" 
      type="files uploads" 
      title="Default File Permissions"
      :allowOverride="settings.isAdminOverrideFile"
      :restrictSwitch="settings.restrictFileSwitch"
      visibilityLabel="All files are always visible to admins."
      :isVisible="settings.isVisibilityFile"
      :isFileOwner=" settings.isFileOwner"
      :restrictActions="fileRestrictions"
      @input="getOverrideValue"
      @change="getVisibleValue"
      @restriction="getRestrictionsValue"
      @owner="isFileOwner = $event"
    />

    <div class="w-full flex justify-between items-center gap-3 mt-10">
      <div class="flex justify-start items-start gap-1">
        <Button 
          @click="saveDocSetting" :disabled="disableBtn"
          class="text-white bg-dynamicBackBtn w-36 h-11 flex justify-center items-center"
        >
          Save Changes
        </Button>
        <Button
          @click="revertChanges"
          class="text-darkPurple bg-white w-40 h-11 flex justify-center items-center"
        >
          Revert Changes
        </Button>
      </div>

      <div class="w-72 flex flex-col justify-center items-center gap-1">
        <div class="w-full flex justify-between items-center gap-1">
          <div class="flex gap-1">
            <icon icon-name="storage" class-name="text-darkPurple" size="xs"/>
            <p class="font-extrabold size-10 text-darkPurple">Storage Capacity</p>
          </div>
          <icon icon-name="icon-badge" class-name="text-accentSquash" size="xs"/>
        </div>

        <div class="w-full h-2 bg-cultured rounded-md">
          <div class="h-full bg-blueCrayola rounded-md"
            :style="`width: ${usedGBPercentage}%`"
          >
          </div>
        </div>

        <p class="w-full text-left font-normal size-10">
          <span class="text-blueCrayola">{{  usedStorageGB }} used </span>
          <span class="text-romanSilver">of {{ totalStorageGB }} </span>
        </p>
      </div>

      <div class="w-40 h-12 p-1 rounded flex flex-col justify-center items-center dome">
        <div class="w-full flex justify-start items-center gap-1">
          <icon icon-name="union-icon" class-name="text-dome" size="xs"/>
          <p class="text-dome font-extrabold text-xs">Upgrade on Dome</p>
        </div>
        <p class="w-full mx-auto text-justify text-jet size font-normal">
          Dome gives you more storage options for your all organization’s documents.
        </p>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Documents",
  components: {
    FileUploadFolderCreation: () => import('./FileUploadFolderCreation'),
    Permissions: () => import('./Permissions'),
    Button: () => import('@/components/Button'),
  },
  data() {
    return {
      disableBtn: false,
      usedGigabytes: 0,
      totalGigabytes: 0,

      storageSettings: {
        createOptionBox: [
          { 
              name: "Yes",
              radioName: "folders", 
              value: true 
          },
          { 
              name: "No", 
              radioName: "folders", 
              value: false 
          }
        ],
        createFolders: true,
        uploadOptionBox: [
          { 
              name: "Yes", 
              radioName: "files", 
              value: true 
          },
          { 
              name: "No", 
              radioName: "files", 
              value: false 
          }
        ],
        uploadFile: true,
        isAdminOverrideFolders: false,
        restrictFolderSwitch: true,
        isVisibilityFolder: true,
        folderRestrictActions: [
            { 
                name: "Uploading Files",
                isChecked: true, 
            },
            { 
                name: "Downloading Files",
                isChecked: false,  
            },
            { 
                name: "Sharing Files",
                isChecked: false,  
            },
            { 
                name: "Deleting Files",
                isChecked: true,  
            },
            { 
                name: "Moving Files",
                isChecked: false,  
            },
        ],
        isAdminOverrideFile: true,
        restrictFileSwitch: true,
        isVisibilityFile: false,
        isFileOwner: true,
        fileRestrictActions: [
            { 
                name: "Allow Downloading",
                isChecked: false,
            },
            { 
                name: "Allow Sharing",
                isChecked: true, 
            },
            { 
                name: "Allow Deleting",
                isChecked: false, 
            },
            { 
                name: "Allow Moving",
                isChecked: false, 
            },
        ],
      },
    };
  },
  computed: {
    folderCreation() {
      return this.storageSettings.createFolders
    },
    fileUpload() {
      return this.storageSettings.uploadFile
    },
    folderOptions() {
      return this.storageSettings.createOptionBox
    },
    uploadOption() {
      return this.storageSettings.uploadOptionBox
    },
    folderRestrictions() {
      return this.storageSettings.folderRestrictActions
    },
    fileRestrictions() {
      return this.storageSettings.fileRestrictActions
    },
    settings(){
      return this.storageSettings
    },
    totalStorageGB() {
      return this.bytesToSize(this.totalGigabytes)
    },
    usedStorageGB() {
      return this.bytesToSize(this.usedGigabytes)
    },
    usedGBPercentage() {
      if (this.totalGigabytes === 0) {
        return 0;
      }
      return (this.usedGigabytes / this.totalGigabytes) * 100;
    },

  },
  methods: {
    getOverrideValue(value, type) {
      if(type === "created folders"){
        return this.storageSettings.isAdminOverrideFolders = value
      }
       if(type === "files uploads"){
        return this.storageSettings.isAdminOverrideFile = value
      }
    },
    getVisibleValue(value, type) {
      if(type === "created folders"){
        return this.storageSettings.isVisibilityFolder = value
      }
       if(type === "files uploads"){
        return this.storageSettings.isVisibilityFile = value
      }
    },
    getRestrictionsValue(value, type) {
      if(type === "created folders"){
        this.storageSettings.folderRestrictActions = this.storageSettings.folderRestrictActions.map(item => {
            return item.name === value.name ? value : item
          })
      }
       if(type === "files uploads"){
        this.storageSettings.fileRestrictActions = this.storageSettings.fileRestrictActions.map(item => {
           return item.name === value.name ? value : item
          })
      }
    },

    getStorageCapacity(){
      this.$_getDocStorage().then(res => {
        if(res.data.data.summary){
          this.usedGigabytes = res.data.data.summary.orgDocAllocatedSpace;
        }
      });
    },

    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return `${0} Bytes`
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    },

    getDocSetting(){
      this.$_getDocSetting().then(res => {
         if(res.data.data.setting !== null){
          const { setting } = res.data.data
          this.totalGigabytes = setting.allocatedSpace

          return this.storageSettings = {...this.storageSettings,
            createFolders: setting.allowEmployeeCreateFolder,
            uploadFile: setting.allowEmployeeUploadFile,
            isAdminOverrideFolders: setting.allowAdminOverride,
            isVisibilityFolder: setting.allowFolderVisibilityToAdmin,
            folderRestrictActions: [
              { 
                name: "Uploading Files",
                isChecked: setting.allowUploadFile, 
              },
              { 
                  name: "Downloading Files",
                  isChecked: setting.allowDownloadFile,  
              },
              { 
                  name: "Sharing Files",
                  isChecked: setting.allowShareFile,  
              },
              { 
                  name: "Deleting Files",
                  isChecked: setting.allowDeleteFile,  
              },
              { 
                  name: "Moving Files",
                  isChecked: setting.allowMoveFile,  
              },
            ],
            isVisibilityFile: setting.allowFileVisibilityToAdmin,
            isFileOwner: setting.fileOwnerExemptedFromRestrictions,
          }
        }else{
          return this.storageSettings
        }
      });
    },

    async saveDocSetting(){
      const payload = {
        allowEmployeeCreateFolder: this.storageSettings.createFolders,
        allowEmployeeUploadFile: this.storageSettings.uploadFile,
        allowAdminOverride: this.storageSettings.isAdminOverrideFolders,
        allowFolderVisibilityToAdmin: this.storageSettings.isVisibilityFolder,
        allowFileVisibilityToAdmin: this.storageSettings.isVisibilityFile,
        allowUploadFile:  this.storageSettings.folderRestrictActions[0].isChecked,
        allowDownloadFile: this.storageSettings.folderRestrictActions[1].isChecked,
        allowShareFile:  this.storageSettings.folderRestrictActions[2].isChecked,
        allowDeleteFile: this.storageSettings.folderRestrictActions[3].isChecked,
        allowMoveFile:  this.storageSettings.folderRestrictActions[4].isChecked,
        fileOwnerExemptedFromRestrictions: this.storageSettings.isFileOwner
      }

      this.disableBtn = true;
      try{
        await this.$_saveDocSettings(payload);
        this.disableBtn = false;
        this.getDocSetting();

        return this.$toasted.success(
            `Document changes successfully made.`,
            {
                duration: 6000,
            }
        );
      }catch(err){
        this.disableBtn = false;
        return this.$toasted.error(
                `Error trying to save changes please check your internet conncetion`,
                {
                  duration: 6000,
                }
        );
      }
    },

    revertChanges(){
      this.getDocSetting()
      return this.$toasted.success(
          `Document changes successfully reverted.`,
          {
              duration: 6000,
          }
      );
    }
  },
  mounted(){
    this.getStorageCapacity();
    this.getDocSetting()
  }
};
</script>

<style scoped>
.dome{
  background: #13B56A0D !important;
}
.size-10{
  font-size: 10px !important;
}
.size{
  font-size: 8px !important;
}
</style>